import { toast } from "react-hot-toast";
import Cookies from "js-cookie";

export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 8)}...`;
  return sortAdd;
}

export function validUrl(value) {
  const re =
    /((http|https):\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  return re.test(value);
}

export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = endDate * 1000 - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};

export function sessionLogout(error) {
  try {
    if (error.response) {
      console.log(
        "  --- sessionLogout - --->>",
        error.response.data.responseCode
      );
      if (
        // eslint-disable-next-line
        error.response.data.responseCode == 440 || // eslint-disable-next-line
        error.response.data.responseCode == 404
      ) {
        toast(`Session expired!`, {
          duration: 7000,
          position: "top-center",
          style: {
            background: "#cf2626",
            color: "#fff",
            fontSize: "18px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          },
        });
        localStorage.removeItem("token");
        Cookies.remove("LiveMode");
        window.location.href = "/login";
        console.log(" --- sessionLogout");
        // return <Redirect to="/login" />;
      }
    }
  } catch (error) {
    console.error(" --- sessionLogout -", error);
  }
}
export function copyTextByValue(value) {
  // var copyText = document.getElementById(id);
  // copyText.select();
  // copyText.setSelectionRange(0, 99999); /* For mobile devices */
  navigator?.clipboard?.writeText(value);
  toast(`Copied   ${sortAddress(value)}`, {
    style: {
      background: "#568de5",
      color: "#fff",
    },
  });
}

export function changeExtenstion(str) {
  const checkFormat1 = str.includes(".png");
  const checkFormat2 = str.includes(".jpg");
  const checkFormat3 = str.includes(".jpeg");
  const checkFormat4 = str.includes(".webp");

  if (checkFormat1) {
    return str.replace(".png", ".webp");
  }
  if (checkFormat2) {
    return str.replace(".jpg", ".webp");
  }
  if (checkFormat3) {
    return str.replace(".jpeg", ".webp");
  }
  if (checkFormat4) {
    return str;
  }
}
