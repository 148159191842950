import React, { useEffect, useState, useContext } from "react";
import { useLocation, matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Button,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import NavItem from "./NavItem";
import { MdDashboard } from "react-icons/md";
import { AuthContext } from "src/context/Auth";
import Cookies from "js-cookie";

const sections = [
  {
    items: [
      {
        title: "Quiz Management",
        modules: "",
        icon: MdDashboard,
        href: "/quiz-management",
      },
      {
        title: "User Management",
        modules: "",
        icon: MdDashboard,
        href: "/user-management",
      },
      {
        title: "Transaction Management",
        modules: "",
        icon: MdDashboard,
        href: "/transaction-management",
      },
      {
        title: "Event Management",
        modules: "",
        icon: MdDashboard,
        href: "/event-management",
      },
    ],
  },
];

function renderNavItems({ items, pathname, permission, userType, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
            permission,
            userType,
          }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
  permission,
  userType,
}) {
  const key = item.title + depth;

  if (item.items) {
    // eslint-disable-next-line
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean()}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 242,
    overflow: "hidden",
    borderRight: "1px solid #ff860d5c",
    height: "100%",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    backdropFilter: "blur(44px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  logo1: {
    cursor: "pointer",
    paddingLeft: "20px",
    paddingTop: "21px",
    height: "75px",
  },
  BITBox: {
    background: "#242B66",
    borderRadius: "2px",
  },
  mainBox: {
    padding: "20px",
  },
  Wallet_class: {
    width: "40px",
    height: "40px",
  },
  box1: {
    padding: "10px",
    display: "flex",
    alignItems: "center",
  },
  yourWallet: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "10px",
    marginLeft: "8px",
  },
  ZeroContent: {
    color: "white",
    fontSize: "22px",
    marginTop: "-12px",
    textAlign: "center",
    paddingBottom: "10px",
    fontFamily: "'Inter'",
    "@media(max-width:1280px)": {
      textAlign: "left",
      marginLeft: "56px",
    },
  },

  logoutButton: {
    position: "absolute",
    bottom: "5px",
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: "13px",
    width: "calc(100% - 26px)",
    textAlign: "center",
    "& .MuiButton-label": {
      justifyContent: "center",
    },
  },

  dailogOpen: {
    borderRadius: "25px",
    padding: "20px",

    "& .MuiDialog-paperWidthSm": {
      padding: "20px 30px",
    },
    "& .MuiPaper-root": {
      color: "#494949",
    },
  },
  dialougTitle: {
    padding: "5px 25px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const location = useLocation();
  const permission = auth.userData.permission;
  const [logoutOpen, setLogoutOpen] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    } // eslint-disable-next-line
  }, [location.pathname]);

  const logoutHandler = () => {
    auth.setUserData({});
    window.localStorage.removeItem("token");
    auth.userLogIn(false, null);
    Cookies.remove("jwt");
    history.push("/login");
  };
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box height="100%" display="flex" flexDirection="column">
        <Box height="100%" display="flex" flexDirection="column">
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Box>
              <Box mb={2}>
                <img
                  src="images/logo_1.png"
                  className={classes.logo1}
                  onClick={() => history.push("/")}
                  alt=""
                />
              </Box>

              {sections.map((section, j) => {
                return (
                  <List
                    key={`menu${j}`}
                    subheader={
                      <ListSubheader disableGutters disableSticky>
                        {section.subheader}
                      </ListSubheader>
                    }
                  >
                    {renderNavItems({
                      items: section?.items,
                      pathname: location.pathname,
                      permission: permission,
                      userType: auth.userData.userType,
                    })}
                  </List>
                );
              })}
            </Box>
            <Box className={classes.hoverText}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setLogoutOpen(true)}
                className={classes.logoutButton}
              >
                <ExitToAppIcon />
                &nbsp;Logout
              </Button>
            </Box>
          </PerfectScrollbar>
          {logoutOpen && (
            <ConfirmationDialog
              open={logoutOpen}
              title={"LOGOUT"}
              desc="Are you sure want to logout ?"
              logoutHandler={logoutHandler}
              handleClose={() => setLogoutOpen(false)}
            />
          )}
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          style={{ width: "42px" }}
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
