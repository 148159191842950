import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import HomeLayout1 from "src/layouts/HomeLayout/layout1";
import LoginLayout from "src/layouts/LoginLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
export const routes = [
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Login")),
  },
  {
    guard: true,
    guest: false,
    exact: true,
    path: "/user-profile",
    layout: HomeLayout1,
    component: lazy(() => import("src/views/pages/UserProfile/UserProfile")),
  },

  {
    guard: true,
    guest: false,
    exact: true,
    path: "/quiz-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/ListQuizManagement")),
  },
  {
    guard: true,
    guest: false,
    exact: true,
    path: "/event-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/EventManagement")),
  },
  {
    guard: true,
    guest: false,
    exact: true,
    path: "/user-management",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Admin/UserManagement")),
  },
  {
    guard: true,
    guest: false,
    exact: true,
    path: "/transaction-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/TransactionManagement")
    ),
  },
  {
    guard: true,
    guest: false,
    exact: true,
    path: "/view-user",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/UserManagement/ViewUser")
    ),
  },
  {
    guard: true,
    guest: false,
    exact: true,
    path: "/add-quiz",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Admin/QuizManagement/AddQuiz")
    ),
  },
  {
    exact: true,
    path: "/register",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Register")),
  },
  {
    exact: true,
    path: "/forget",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Forget")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Auth/Verifyotp")),
  },
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/LandingBanner1")),
  },
  {
    guard: true,
    guest: true,
    exact: true,
    path: "/fortune-pool",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/FortunePool/index")),
  },

  {
    exact: true,
    path: "/term-condition",
    layout: HomeLayout1,
    component: lazy(() => import("src/views/pages/T&C/Term")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout1,
    component: lazy(() => import("src/views/pages/Privacy/Privacy")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
