import React from "react";
import { makeStyles, Box } from "@material-ui/core";
// import { useHistory } from "react-router-dom";

import TopBar from "./TopBar";
import RandomVideoPlayer from "src/component/RandomVideoPlayer";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "#000",
    // background: "url('images/background.jpg')",
    background: "url('images/')",
    overflow: "hidden",
    position: "relative",
    height: "100dvh",
  },
  MainLayout: {
    height: "calc(100dvh - 83px)",
    // position: "relative",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - 66px)",
    },
  },
  videoContainer: {
    // position: "relative",
    // overflow: "hidden",
    height: "calc(100dvh - 73px)",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - 56px)",
    },
    "&::after": {
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      content: "''",
      zIndex: "1",
      position: "absolute",
      background:
        "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.85) 100%)",
    },
  },
  contentContainer1: {
    // height: "calc(100dvh - 56px)",
    height: "calc(100dvh - 73px)",
    overflow: "hidden",
    // position: "relative",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - 56px)",
    },
    "& .videoBox": {
      zIndex: "1",
      position: "absolute",
      height: "auto",
      width: "auto",
      minWidth: "100%",
      minHeight: "100%",
    },
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  // const history = useHistory();

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.contentContainer1}>
        <Box className={classes.videoContainer}>
          <RandomVideoPlayer />
          <div className={classes.MainLayout}>{children}</div>
        </Box>
      </div>
    </div>
  );
};

export default MainLayout;
