import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { calculateTimeLeft, sessionLogout } from "src/utils";
import ApiConfig, { socketUrl } from "src/apiconfig/apiconfig";
import { getDataHandlerWithTokenParams } from "src/apiconfig/service";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";
import moment from "moment-timezone";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("token");
  return accessToken ? true : false;
}
function checkGuestUserin() {
  const accessToken = window.sessionStorage.getItem("guest");
  return accessToken ? true : false;
}
function checkLiveMode() {
  const accessToken = Cookies.get("LiveMode");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  // const history = useHistory();
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [isGuest, setisGuest] = useState(checkGuestUserin());
  const [isLiveMode, setIsLiveMode] = useState(checkLiveMode());
  const [userData, setUserData] = useState({});
  const [ActiveRoom, setActiveRoom] = useState({});
  const [ipAddresses, setipAddresses] = useState();
  const [count, setcount] = useState(false);
  const [endTime, setEndtime] = useState();
  const [nextLive, setNextLive] = useState("00");
  const [nextLiveEnd, setNextLiveEnd] = useState();
  const [gameModeOn, setgameModeOn] = useState(false);
  const [isRoutes, setIsRoutes] = useState(false);
  const [second, setSecond] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [roomId, setroomId] = useState();
  const [isTrivia, setisTrivia] = useState(false);
  const [isUSIP, setisUSIP] = useState(false);
  const [expiryTime, setexpiryTime] = useState(0);
  const [myBankAccount, setMyBankAccount] = useState([]);
  const [bankStatus, setBankStatus] = useState(false);
  const [liveMode, setLiveMode] = useState({
    isliveModeWheel: false,
    isSecondChance: false,
    isShowOneMinMess: false,
    isDrag: true,
  });
  const [cotageryList, setCotageryList] = useState();
  let token = window.localStorage.getItem("token");

  useEffect(() => {
    try {
      const web = new WebSocket(socketUrl);
      web.onopen = () => {
        web.send(JSON.stringify({ option: "roomList" }));
        web.onmessage = async (event) => {
          if (event.data !== "[object Promise]" && event.data !== "null") {
            let obj = JSON.parse(event.data);
            if (obj && obj.length > 0) {
              setNextLiveEnd(obj[0].fromTime);
              setActiveRoom(obj[0]);
              setroomId(obj[0]._id);
            } else {
              setNextLiveEnd();
            }
          }
        };
      };
      return () => {
        web.close();
      };
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const ListTopFiveQuizForGuestUser = async () => {
      try {
        const res = await getDataHandlerWithTokenParams(
          "listTopFiveQuizForGuestUser"
        );
        if (res.data.result) {
          let result = res.data.result[0];
          setexpiryTime(result?.expiryTime ? result?.expiryTime : 8);
        }
      } catch (err) {
        console.log("err --", err);
      }
    };
    ListTopFiveQuizForGuestUser();
  }, []);

  useEffect(() => {
    var isLive;
    if (!nextLiveEnd) {
      setNextLive("00:00");
      isLive = false;
      return;
    }
    const interval = setInterval(() => {
      // setTimeRemaining(calculateTimeRemaining(nextLiveEnd));
      // IST
      // const currentTime = moment.tz("Asia/Kolkata");
      // const target = moment.tz(nextLiveEnd, "HH:mm:ss", "Asia/Kolkata");

      // EST
      const currentTime = moment.tz("America/New_York");
      const target = moment.tz(nextLiveEnd, "HH:mm:ss", "America/New_York");
      const duration = moment.duration(target.diff(currentTime));
      // *********************************************************************
      let remainingTime = duration._milliseconds;
      if (remainingTime <= 0) {
        // targetTimestamp.setDate(targetTimestamp.getDate() + 1);
        // remainingTime = targetTimestamp - new Date();
        return;
      }
      let hours = duration.hours();
      let minutes = duration.minutes();
      let seconds = duration.seconds();
      let formattedTime;
      if (Number(remainingTime) > Number(90000)) {
        isLive = false;
        let updatedHours = hours;
        let updatedMinutes = minutes - 1;
        let updatedSeconds = seconds - 30;
        // Handle the case
        if (updatedSeconds < 0) {
          updatedMinutes -= 1;
          updatedSeconds += 60;
        }
        if (updatedMinutes < 0) {
          updatedMinutes += 60;
          updatedHours -= 1;
        }
        formattedTime = `${(updatedHours % 24)
          .toString()
          .padStart(2, "0")}:${updatedMinutes
          .toString()
          .padStart(2, "0")}:${updatedSeconds.toString().padStart(2, "0")}`;
      } else {
        // isLive = true;
        formattedTime = `${(hours % 24).toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      }
      setSecond(seconds.toString().padStart(2, "0"));
      if (hours % 24 == 0 && minutes == 1 && seconds == 30) {
        setIsRoutes(true);
        Cookies.set("LiveMode", "You Are eligible for live game mode", {
          expires: 1 / (24 * 60),
        });
        toast(`Live mode on!`, {
          duration: 2 * 60 * 1000,
          position: "bottom-right",
          style: {
            background: "green",
            color: "#fff",
            fontSize: "16px",
            padding: "3px",
            marginTop: "0px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          },
        });
      }
      if (hours % 24 == 0 && minutes <= 1 && seconds <= 30) {
        setgameModeOn(false);
        isLive = true;
        setIsLiveMode(true);
        setLiveMode({
          isliveModeWheel: true,
          isSecondChance: false,
          isDrag: true,
          isShowOneMinMess: true,
        });
      }

      if (hours % 24 == 0 && minutes <= 0 && seconds <= 1) {
        setgameModeOn(true);
        setTimeout(() => {
          isLive = false;
          setIsLiveMode(false);
          setgameModeOn(false);
          Cookies.remove("LiveMode");
        }, 900000);
        setLiveMode({
          isliveModeWheel: false,
          isDrag: true,
          isSecondChance: false,
          isShowOneMinMess: false,
        });
      }
      setNextLive(formattedTime);
      setcount(isLive);
      // *********************************************************************
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [nextLiveEnd, isGuest]);

  useEffect(() => {
    getPublicIPAddress();
    if (token) {
      getProfile(token);
    } // eslint-disable-next-line
  }, [token]);
  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  function getPublicIPAddress() {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        const ipAddress = data.ip;
        // getCountryByIPAddress(ipAddress);
        setipAddresses(ipAddress);
      })
      .catch((error) => console.error("Error fetching IP address:", error));
  }

  const getProfile = async (token) => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.profile,
        headers: {
          token: token,
        },
        params: {
          ipAddress: ipAddresses,
        },
      });
      if (res.data.responseCode === 200) {
        setUserData(res.data.result);
      } else {
      }
    } catch (error) {
      console.log(error);
      sessionLogout(error);
    }
  };

  let data = {
    userLoggedIn: isLogin,
    guestUserLoggedIn: isGuest,
    userInLivemode: isLiveMode,
    setisGuest,
    isUSIP,
    roomId,
    liveMode,
    myBankAccount,
    second,
    userData,
    setEndtime,
    nextLive,
    timeLeft,
    isTrivia,
    setisTrivia,
    cotageryList,
    setIsLiveMode,
    bankStatus,
    setLiveMode,
    ipAddresses,
    setUserData,
    expiryTime,
    gameModeOn,
    isRoutes,
    setIsRoutes,
    count,
    ActiveRoom,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    getProfileData: () => {
      getProfile(window.localStorage.getItem("token"));
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
