import React, { useState, useEffect } from "react";

const LandscapeWarning = () => {
  const [isLandscape, setIsLandscape] = useState(false);

  const handleOrientationChange = () => {
    setIsLandscape(window.orientation === 90 || window.orientation === -90);
  };

  useEffect(() => {
    handleOrientationChange(); // Initial check
    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    }; // eslint-disable-next-line
  }, [window]);

  if (isLandscape) {
    return (
      <div style={styles.warningContainer}>
        <p style={styles.warningText}>
          Please rotate your device to portrait mode for a better experience.
        </p>
      </div>
    );
  }

  return null;
};

const styles = {
  warningContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 9999,
  },
  warningText: {
    color: "white",
    fontSize: "18px",
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#FF6347",
    borderRadius: "8px",
  },
};

export default LandscapeWarning;
