import React, { useContext } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  Button,
  Box,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import ButtonCircularProgress from "./ButtonCircularProgress";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  modaltext: {
    "& h5": {
      fontWeight: "300",
      "& span": {
        color: "rgba(255, 255, 255, 0.6)",
      },
    },
    "& .buttonboxes": {
      display: "flex",
      alignItems: "center",
      padding: "30px",
      "@media(max-width:442px)": {
        display: "block",
        padding: "20px",
      },
      "& button": {
        margin: "0px 10px",
        "@media(max-width:442px)": {
          margin: "10px 0px 0px",
        },
      },
    },
    "& h3": {
      fontSize: "24px",
      fontWeight: "300",
      textAlign: "center",
    },
  },
}));

export default function ConfirmationDialog({
  title,size,
  desc,
  IsLoading,
  open,
  handleClose,
  logoutHandler,
  actionHandlerAPI,
  confirmationHandler,
  btnText,
}) {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  return (
    <div>
      {title === "LOGOUT" ? (
        <Dialog
          open={open}
          onClose={() => {
            if (!IsLoading) {
              handleClose();
            }
          }}
          // TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={size ? size :"xs"}
        >
          <DialogContent>
            <Box className="displaySpacebetween">
              <IconButton
                style={{ position: "absolute", right: "0px", top: "0px" }}
              >
                <ClearIcon onClick={handleClose} style={{ color: "#fff" }} />
              </IconButton>
            </Box>
            <Box className={classes.modaltext}>
              <Box mt={2} mb={2}>
                <Typography variant="h2" align="center">
                  Logout
                </Typography>
              </Box>
              <Box mt={2} mb={1}>
                <Typography variant="h3">Do you want to logout?</Typography>
              </Box>
              <Box className="buttonboxes">
                <Button
                  variant="outlined"
                  size="large"
                  color="secondary"
                  style={{ borderRadius: "50px" }}
                  fullWidth
                  onClick={handleClose}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={() => {
                    handleClose();
                    logoutHandler();
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          open={open}
          onClose={() => {
            if (!IsLoading) {
              handleClose();
            }
          }}
          // TransitionComponent={Transition}
          // keepMounted
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >         
          <DialogContent>
            <Box className="displaySpacebetween">
              <IconButton
                style={{ position: "absolute", right: "0px", top: "0px" }}
              >
                <ClearIcon onClick={handleClose} style={{ color: "#fff" }} />
              </IconButton>
            </Box>
            <Box className={classes.modaltext}>
              <Box mt={2} mb={2}>
                <Typography variant="h2" align="center">
                  {title}
                </Typography>
              </Box>
              <Box mt={2} mb={1}>
                <Typography variant="h3">{desc}</Typography>
              </Box>
              <Box className="buttonboxes">
                <Button
                  variant="outlined"
                  size="large"
                  color="secondary"
                  style={{ borderRadius: "50px" }}
                  fullWidth
                  // onClick={handleClose}
                  onClick={() => {
                    if (btnText) {
                      if (auth.userInLivemode) {
                        auth?.setIsRoutes(false);
                      }
                      window.sessionStorage.setItem("guest", "LogesIn");
                      auth.setisGuest(true);
                      history.push(`/fortune-pool?guest`);
                      handleClose();
                    } else {
                      handleClose();
                    }
                  }}
                >
                  {btnText ? "Play for Fun" : "No"}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={actionHandlerAPI}
                  disabled={IsLoading}
                >
                  {btnText ? btnText : "Yes"}{" "}
                  {IsLoading && <ButtonCircularProgress />}
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
