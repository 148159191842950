import React, { useState, useEffect } from "react";
const videos = [
  "images/video/MovieStripes.mp4",
  "images/video/MovieStripes2.mp4",
  "images/video/MovieStripes3.mp4",
  "images/video/MovieStripes4.mp4",
  "images/video/MovieStripes5.mp4",
  "images/video/MovieStripes6.mp4",
  "images/video/MovieStripes7.mp4",
  "images/video/MovieStripes8.mp4",
];
const getRandomVideo = () => {
  const randomIndex = Math.floor(Math.random() * videos.length);
  return videos[randomIndex];
};
const RandomVideoPlayer = () => {
  const [randomVideo, setRandomVideo] = useState(getRandomVideo());

  useEffect(() => {
    setRandomVideo(getRandomVideo());
  }, []);

  return (
    <video
      autoPlay
      loop
      muted
      className="videoBox"
      playsInline
      poster="images/pool_banner_1.jpg"
    >
      <source src={randomVideo} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default RandomVideoPlayer;
